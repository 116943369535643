import {
    CAlert,
    CRow,
    CCol,
    CFormGroup,
    CLabel,
    CInput,
    CButton,
    CSpinner,
    CSelect,
    CInputCheckbox,
    CTextarea
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import imageCompression from "browser-image-compression";
import { useHistory } from "react-router-dom";
import Breadcrumb from "../Breadcrumb";

import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { API_BASE_URL, tokenCookie, keyHeader, sizeAlert } from "src/config";
import { Basic, Scroll, Categori, Adresse, Contact, Extra, Imgs } from "src/components/myWidgets/formTitles";
import Loading from "src/components/myWidgets/loadingWidget";
import { addWatermark, addWatermarkToAlbum } from "src/actions";
import { isLogin, logoutLocal } from "src/components/middleware/auth";
import validator from "validator";
import apiClient from "../apiClient";

export default function Consult() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [success, setSuccess] = useState(false);
    const [description, setDescription] = useState("");
    const [loading, setLoading] = useState("");
    const [country, setCountry] = useState("");
    const [countries, setCountries] = useState([]);
    const [city, setCity] = useState("");
    const [cities, setCities] = useState([]);
    const [delegate, setDelegate] = useState("");
    const [delegates, setDelegates] = useState([]);
    const [name, setName] = useState("");
    const [newPhone, setNewPhone] = useState(true);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [makes, setMakes] = useState([]);
    const [make, setMake] = useState("");
    const [models, setModels] = useState([]);
    const [model, setModel] = useState("");
    const [isLoaded1, setIsLoaded1] = useState(false);


    const history = useHistory();

    const handleSubmit = async (e) => {

        e.preventDefault();
        setLoading(true);
        {
            setSubmitted(true);
            if (name && city &&
                ((phoneNumber.length == 0 && isLogin()) || isValidPhoneNumber(phoneNumber))) {
                setLoading(true);

                const formData = new FormData();
                formData.append("name", name);

                formData.append("note", description);
                formData.append("brand", make);

                formData.append("city", city);

                if (newPhone) formData.append("phone", phoneNumber);

                //console.log(formData.get("imgPrincipal"));
                axios
                    .post(`${API_BASE_URL}/consults/add`, formData)
                    .then((res) => {
                        console.log(res);
                        if (res.data.status.toString() === "ok") {
                            setSuccess(true);
                            window.scrollTo(0, 50);
                        }
                        setLoading(false);
                    })
                    .catch((error) => {
                        //console.log(" errors : ", error);
                        setLoading(false);
                        if (error.response && error.response.status === 401) {
                            logoutLocal();
                            window.location.replace("/members/login");
                        }
                    });
            } else {
                window.scrollTo(0, 200);
                setLoading(false);
            }
        }

    };

    const handleSuccess = () => {
        setSuccess(false);
        window.scrollTo(0, 50);
    }

    useEffect(() => {
        console.log('islogg', isLogin())
        if (!localStorage.getItem("countries")) {
            axios(`${API_BASE_URL}/countries/all`)
                .then((res) => res.data)
                .then(
                    (result) => {
                        setCountries(result);
                        handleCountryChange("1")
                        localStorage.setItem("countries", JSON.stringify(result));
                    },
                    (error) => { }
                );
        } else {
            setCountries(JSON.parse(localStorage.getItem("countries")));
            handleCountryChange("1")
        }

        if (!localStorage.getItem("carBrands")) {
            axios(`${API_BASE_URL}/vehicles/car-brands`)
                .then((res) => res.data)
                .then(
                    (result) => {
                        setIsLoaded(true);
                        setMakes(result);
                        //  console.log(result);
                        localStorage.setItem("carBrands", JSON.stringify(result));

                    },
                    (error) => {
                        setIsLoaded(true);
                    }
                );
        } else {
            setMakes(JSON.parse(localStorage.getItem("carBrands")));
            setIsLoaded(true);
        }


    }, [country]);

    const handleCountryChange = (e) => {
        setCountry(e);
        let id = e;
        var countriesF = [];
        console.log("iddd", id);
        if (id !== "0") {
            countriesF = countries.find((city) => city.id.toString() === id);

            if (countriesF) setCities(countriesF.cities);
            console.log("countriesF", countriesF);
            console.log("countriess", countries);
        } else {
            setCity([]);
            //  setModel("");
        }
    };

    const handleCityChange = (e) => {
        setCity(e.target.value);
        let id = e.target.value;
        var citiesF = [];
        //console.log("iddd", id);
        if (id !== "0") {
            citiesF = cities.find((delegate) => delegate.id.toString() === id);
            setDelegates(citiesF.delegations);
            //console.log("citiesF", citiesF);
        } else {
            setDelegate([]);
            //  setModel("");
        }
    };

    const handleMakeChange = (e) => {
        setMake(e.target.value);
        let id = e.target.value;
        var makesF = [];
        if (id !== "0") {
            makesF = makes.find((model) => model.id.toString() === id);
            //setModels(makesF.models);
            //setModel("");
            // console.log(makesF);
        } else {
            //setModels([]);
            //setModel("");
        }
    };

    const changePhoneNumber = (event) => {
        //console.log(event.target.checked);
        if (event.target.checked) { setNewPhone(false); setPhoneNumber(""); }
        else {
            setNewPhone(true);
        }
    }
    const paddingVal = '45px';
    if (error) {
        return (
            <div className="container">
                <main className="l-main">
                    <div className="l-section l-section--archive">
                        <div>Erreur : {error.message}</div>
                    </div>
                </main>
            </div>
        );
    } else if (!isLoaded) {
        return (
            <Loading />
        );
    } else {
        return (
            <>
                <div className="vehicle-search">
                    <div className="container consultForm">
                        <Breadcrumb />
                        <br /> <br />
                        <CRow xs="12" className="consultTextDesktop" >
                            <CCol xs="6" style={{ paddingRight: paddingVal }}>
                                <div className="consultText">
                                    <h3> Prenez Rendez-vous <br /> pour une Expertise Auto !
                                    </h3>
                                    <p>
                                        Vous souhaitez évaluer votre véhicule avant une vente ou un achat ? <br /> Avec Tunisia Living Trafic, bénéficiez d'une expertise professionnelle et rapide pour garantir la transparence et la sécurité de vos transactions.
                                    </p>
                                    <p>
                                        <strong>Comment ça marche ? </strong>
                                        <br />
                                        Remplissez le formulaire en ligne avec les informations nécessaires. <br />
                                        Envoyez votre demande par un clic.</p>
                                    <p> Nos agents vous contacterons pour fixer un rendez-vous.
                                    </p>
                                    <p>
                                        N'attendez plus ! Prenez rendez-vous dès maintenant pour une expertise en toute sérénité et assurez-vous de faire le bon choix.
                                    </p>
                                </div></CCol>
                            <CCol xs="6" style={{ paddingLeft: paddingVal }}>
                                <div className="consultText consultText2">

                                    <img class="c-logo__image" src="assets/images/expertise-auto.png" alt="" />
                                    <br />
                                    <p>
                                        <strong> Pourquoi choisir Tunisia Living Trafic ?
                                        </strong> <ul>
                                            <li>
                                                ✔ Expertise réalisée par des professionnels qualifiés.</li>
                                            <li>
                                                ✔ Rapport détaillé sur l'état général du véhicule.</li>
                                            <li>
                                                ✔ Service fiable et accessible à tous.</li>
                                        </ul>
                                    </p>
                                </div>
                            </CCol>
                        </CRow> <br />

                        <CRow xs="12" className="consultTextMob" >
                            <CCol xs="8" style={{ paddingRight: paddingVal }}>
                                <div className="consultText">
                                    <h3> Prenez Rendez-vous <br /> pour une Expertise Auto !
                                    </h3>
                                </div>
                            </CCol>
                            <CCol xs="4" >
                                <div className="consultText consultText2">

                                    <img class="c-logo__image" src="assets/images/expertise-auto.png" alt="" />
                                    <br />
                                </div>
                            </CCol>
                            <CCol xs="12" >
                                <div className="consultText">
                                    <p>
                                        Vous souhaitez évaluer votre véhicule avant une vente ou un achat ? <br /> Avec Tunisia Living Trafic, bénéficiez d'une expertise professionnelle et rapide pour garantir la transparence et la sécurité de vos transactions.
                                    </p>
                                    <p>
                                        <strong>Comment ça marche ? </strong>
                                        <br />
                                        Remplissez le formulaire en ligne avec les informations nécessaires. <br />
                                        Envoyez votre demande par un clic.</p>
                                    <p> Nos agents vous contacterons pour fixer un rendez-vous.
                                    </p>
                                    <p>
                                        N'attendez plus ! Prenez rendez-vous dès maintenant pour une expertise en toute sérénité et assurez-vous de faire le bon choix.
                                    </p>
                                </div>
                            </CCol>

                        </CRow> <br />

                        <CRow xs="12">
                            <CCol xs="12" >
                                {success ?
                                    <div className="l-section l-section--archive">
                                        <br /> <br />
                                        <div className="loading_logo successIcon">
                                            <img
                                                src={"assets/icons/success.png"}
                                                alt="tunisia living loading icon"
                                            />
                                            <p style={{ color: "#1c9070" }}>

                                                votre demande a été soumise avec succès, notre expertise vous contactera prochainement

                                            </p>
                                            <br />
                                        </div>

                                        <div>
                                            <CButton
                                                style={{
                                                    width: 'max-content',
                                                    display: 'block',
                                                    margin: 'auto',
                                                    fontSize: '16px'
                                                }} color="primary" block onClick={() => handleSuccess()}>
                                                Soumettre une autre demande
                                            </CButton>
                                            <br /><br />

                                        </div>
                                    </div>


                                    : <form onSubmit={handleSubmit} id="form-add" >

                                        <CRow xs="12">
                                            <CCol xs="6" style={{ paddingRight: paddingVal }}>
                                                <CFormGroup>
                                                    <CLabel htmlFor="name">Nom</CLabel>
                                                    <CInput
                                                        type="text"
                                                        name="name"
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                    ></CInput>
                                                    {submitted && !name && (
                                                        <span className="invalid-feedback error">Obligatoire</span>
                                                    )}
                                                </CFormGroup>
                                            </CCol>
                                            <CCol xs="6" style={{ paddingLeft: paddingVal }}>
                                                {newPhone && <CFormGroup className='formPhoneNumber'>
                                                    <CLabel htmlFor="phoneNumber">Numéro téléphone</CLabel>
                                                    <PhoneInput
                                                        type="text"
                                                        onChange={(value) => setPhoneNumber(value)}
                                                        placeholder="Entrez un numéro de téléphone"
                                                        defaultCountry="TN"
                                                        international
                                                        name="phoneNumber"
                                                        id="phoneNumber"
                                                        value={phoneNumber}
                                                        countries={['TN']}
                                                    />
                                                    {submitted && newPhone && !isValidPhoneNumber(phoneNumber) && (
                                                        <span className="invalid-feedback error">Ce numéro de téléphone n'est pas valide</span>
                                                    )}
                                                </CFormGroup>}

                                                {/* {isLogin() && <CFormGroup variant="checkbox" className="checkbox">
                                                    <CInputCheckbox
                                                        onChange={changePhoneNumber}
                                                    />
                                                    <CLabel variant="checkbox" className="form-check-label">
                                                        Utilisez le numéro téléphone de votre compte
                                                    </CLabel>
                                                </CFormGroup>} */}
                                            </CCol>
                                        </CRow>

                                        <CRow xs="12">
                                            <CCol xs="6" style={{ paddingRight: paddingVal }}>
                                                <CFormGroup>
                                                    <CLabel htmlFor="Région">Gouvernorat</CLabel>
                                                    <CSelect
                                                        name="city"
                                                        value={city}
                                                        onChange={(e) => handleCityChange(e)}
                                                    >
                                                        <option value="0"> Gouvernorat</option>
                                                        {cities.map((city) => {
                                                            return (
                                                                <option key={city.id} value={city.id}>
                                                                    {city.label}
                                                                </option>
                                                            );
                                                        })}
                                                    </CSelect>
                                                    {submitted && !city && (
                                                        <span className="invalid-feedback error">Obligatoire</span>
                                                    )}
                                                </CFormGroup>
                                            </CCol>
                                            <CCol xs="6" style={{ paddingLeft: paddingVal }}>
                                                <CFormGroup>
                                                    <CLabel htmlFor="make">Voiture Marque</CLabel>
                                                    <CSelect
                                                        name="make"
                                                        value={make}
                                                        onChange={(e) => {
                                                            handleMakeChange(e);

                                                            //console.log(e.target);
                                                        }}
                                                    >
                                                        <option value="0" className="diabledoption"> Choisir la marque</option>
                                                        <optgroup label="Top Marques">
                                                            {makes.slice(0, 23).map((make) => {
                                                                return (
                                                                    <option key={make.id} value={make.id}>
                                                                        {make.label}
                                                                    </option>
                                                                );
                                                            })}
                                                        </optgroup>
                                                        <optgroup label="Autres Marques">
                                                            {makes.slice(23).map((make) => {
                                                                return (
                                                                    <option key={make.id} value={make.id}>
                                                                        {make.label}
                                                                    </option>
                                                                );
                                                            }
                                                            )}
                                                        </optgroup>
                                                    </CSelect>
                                                </CFormGroup>
                                            </CCol>
                                        </CRow>


                                        <CRow>
                                            <CCol xs="6" style={{ paddingRight: paddingVal }}>
                                                <CFormGroup>
                                                    <CTextarea
                                                        style={{ height: "75px" }}
                                                        placeholder="Notes"
                                                        name="Notes"
                                                        value={description}
                                                        onChange={(e) => setDescription(e.target.value)}
                                                    />


                                                </CFormGroup>
                                            </CCol>
                                        </CRow>

                                        <CRow>
                                            <CCol md="3" className="offset-9">
                                                {
                                                    loading ? (
                                                        <CButton color="primary" block disabled>
                                                            <span>
                                                                {" "}
                                                                <CSpinner color="default" size="sm" />
                                                            </span>

                                                            <span> Valider</span>
                                                        </CButton>
                                                    )
                                                        :
                                                        <CButton color="primary" block type="submit">
                                                            Valider
                                                        </CButton>

                                                }
                                            </CCol>
                                        </CRow>
                                    </form>
                                }
                            </CCol>
                            {/* <CCol xs="6" style={{ paddingLeft: "25px" }}>
                                <div className="consultText">
                                    <br />
                                     </div>

                            </CCol> */}
                        </CRow>
                        <CRow xs="12" className="consultTextMob">
                            <CCol xs="12" style={{ paddingLeft: paddingVal }}>
                                <div className="consultText consultText2">
                                    <br />
                                    <p>
                                        <strong> Pourquoi choisir Tunisia Living Trafic ?
                                        </strong> <ul>
                                            <li>
                                                ✔ Expertise réalisée par des professionnels qualifiés.</li>
                                            <li>
                                                ✔ Rapport détaillé sur l'état général du véhicule.</li>
                                            <li>
                                                ✔ Service fiable et accessible à tous.</li>
                                        </ul>
                                    </p>
                                </div>
                            </CCol>
                        </CRow>
                    </div>
                </div>
            </>
        );
    }
}
