import React, { useEffect, useState } from "react";
import { API_BASE_URL, tokenU } from "../../../config";
import {
  CBadge,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CButton,
  CCollapse,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CAlert,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { Link } from "react-router-dom";
import axios from "axios";
import apiUser from "../apiUser";

export default function IndexNews() {
  const [modal, setModal] = useState(false);
  const [error, setError] = useState(null);
  const [errorDel, setErrorDel] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const getBadge = (status) => {
    switch (status) {
      case true:
        return "success";
      case false:
        return "secondary";

      default:
        return "primary";
    }
  };

  const [details, setDetails] = useState([]);
  const toggle = () => {
    setModal(!modal);
  };
  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      // newDetails = [...details, index];
      newDetails = [details, index];
    }
    setDetails(newDetails);
  };
  function deleteNews(id) {
    //console.log("itttttems");
    //console.log(items);
    //const { id } = this.props.match.params;
    toggle();
    apiUser
      .delete(`/news/delete/${id}`)
      .then((res) => {
        //console.log("dddddddd");
        //console.log(res);
        if (res.data.status.toString() === "ok") {
          setItems((items) => items.filter((x) => x.id !== id));
        }
        if (
          res.data.status.toString() ===
          "ForeignKeyConstraintViolationException"
        ) {
          setErrorDel("Vous ne pouvez pas supprimer cette actualité");
        }
      })
      .catch((err) => console.log(err));
  }

  function publishNews(id, statusP) {
    const statut = { status: statusP };

    apiUser
      .post(`/news/publish/${id}`, statut)
      .then((res) => {
        if (res.data.status.toString() === "ok") {
          // window.location.reload(false);
          setItems((items) => {
            apiUser
              .get("/news")
              .then((res) => res.data)
              .then(
                (result) => {
                  console.log(result)
                  setIsLoaded(true);

                  setItems(result);
                },
                // Remarque : il faut gérer les erreurs ici plutôt que dans
                // un bloc catch() afin que nous n’avalions pas les exceptions
                // dues à de véritables bugs dans les composants.
                (error) => {
                  setIsLoaded(true);
                  setError(error);
                }
              );
          });
        }
        if (
          res.data.status.toString() ===
          "ForeignKeyConstraintViolationException"
        ) {
          setErrorDel("Vous ne pouvez pas supprimer cette actualité");
        }
      })
      .catch((err) => console.log(err));
  }

  const fields = [
    {
      key: "title",
      label: "libellé",
      _style: { width: "20%" },
    },
    {
      key: "createdByname",
      label: "Auteur",
      _style: { width: "20%" },
    },
    { key: "statuslable", _style: { width: "20%" }, label: "Statut" },

    {
      key: "show_details",
      label: "Actions",
      _style: { width: "1%" },
      sorter: false,
      filter: false,
    },
  ];
  // Remarque : le tableau vide de dépendances [] indique
  // que useEffect ne s’exécutera qu’une fois, un peu comme
  // componentDidMount()
  useEffect(() => {
    apiUser
      .get("/news")
      .then((res) => res.data)
      .then(
        (result) => {
          setIsLoaded(true);
          console.log(result);
          setItems(result);
          let tempresult = result;
          //console.log(tempresult);
          for (let index = 0; index < tempresult.length; index++) {
            //console.log(tempresult[index]);
            // console.log(
            //   tempresult[index].createdBy.prenom +
            //     tempresult[index].createdBy.nom +
            //     "-" +
            //     tempresult[index].status
            // );
            tempresult[index].createdByname =
              tempresult[index].createdBy.prenom +
              " " +
              tempresult[index].createdBy.nom; // Set new field
            if (tempresult[index].status || tempresult[index].status == "true")
              tempresult[index].statuslable = "Publié";
            else tempresult[index].statuslable = "Non publié";
            if (index == tempresult.length - 1) setLoaded(true);
          }
        },
        // Remarque : il faut gérer les erreurs ici plutôt que dans
        // un bloc catch() afin que nous n’avalions pas les exceptions
        // dues à de véritables bugs dans les composants.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  if (error) {
    return <div>Erreur : {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Chargement...</div>;
  } else {
    return (
      <div className="newsDashboard">
        <CRow>
          <CCol xl={12}>
            <Link
              className="btn btn-outline-primary btn-lg "
              to="/5467854/news/add"
            >
              {" "}
              <CIcon name="cilNotes" /> Ajouter un Article
            </Link>
            <CCard>
              <CCardHeader>Articles</CCardHeader>
              <CCardBody>
                {errorDel !== "" ? (
                  <CAlert color="danger" closeButton>
                    {errorDel}
                  </CAlert>
                ) : (
                  ""
                )}
                {loaded && (
                  <CDataTable
                    items={items}
                    fields={fields}
                    columnFilter
                    tableFilter
                    footer
                    itemsPerPageSelect
                    itemsPerPage={10}
                    hover
                    sorter
                    pagination
                    scopedSlots={{
                      title: (item) => (
                        <td className="titleTdNewsTableDashboard">
                          {item.autosaved && <p className="autoSavedLabel">autoSaved</p>}
                          {item.title}
                        </td>
                      ),
                      statuslable: (item) => (
                        <td>
                          <CBadge color={getBadge(item.status)}>
                            {!item.status ? "Non publié " : "Publié "}
                          </CBadge>
                        </td>
                      ),
                      createdBy: (item) => (
                        <td>
                          {item.createdBy.prenom} {item.createdBy.nom}
                        </td>
                      ),
                      show_details: (item, index) => {
                        return (
                          <td className="py-2">
                            <CButton
                              color="primary"
                              variant="outline"
                              shape="square"
                              size="sm"
                              onClick={() => {
                                toggleDetails(index);
                              }}
                            >
                              {details.includes(index) ? "Hide" : "Show"}
                            </CButton>
                          </td>
                        );
                      },
                      details: (item, index) => {
                        return (
                          <CCollapse show={details.includes(index)}>
                            <CCardBody>
                              <h4>{item.title}</h4> <br />
                              <Link to={`/5467854/news/detail/${item.id}`}>
                                <CButton size="sm" color="primary">
                                  Voir détail
                                </CButton>
                              </Link>
                              {localStorage.getItem("roles") ===
                                "ROLE_EDITOR_IN_CHIEF" || localStorage.getItem("roles") ===
                                "ROLE_SUPER_ADMIN" ? (
                                !item.status ? (
                                  <CButton
                                    size="sm"
                                    color="success"
                                    className="ml-1"
                                    onClick={() => {
                                      publishNews(item.id, true);
                                      toggleDetails(index);
                                    }}
                                  >
                                    Publier
                                  </CButton>
                                ) : (
                                  <CButton
                                    size="sm"
                                    color="secondary"
                                    className="ml-1"
                                    onClick={() => {
                                      publishNews(item.id, false);
                                      toggleDetails(index);
                                    }}
                                  >
                                    Dépublier
                                  </CButton>
                                )
                              ) : (
                                ""
                              )}
                              <Link to={`/5467854/news/edit/${item.id}`}>
                                <CButton
                                  size="sm"
                                  color="info"
                                  className="ml-1"
                                >
                                  Modifier
                                </CButton>
                              </Link>
                              <CButton
                                size="sm"
                                color="danger"
                                className="ml-1"
                                onClick={toggle}
                              >
                                Supprimer
                              </CButton>
                              <CModal show={modal} onClose={toggle}>
                                <CModalHeader closeButton></CModalHeader>
                                <CModalBody>
                                  Voulez vous vraiment supprimer ce type
                                  d'actualité ?
                                </CModalBody>
                                <CModalFooter>
                                  <CButton
                                    color="primary"
                                    onClick={() => {
                                      deleteNews(item.id);
                                      toggleDetails(index);
                                    }}
                                  >
                                    Oui
                                  </CButton>

                                  <CButton color="secondary" onClick={toggle}>
                                    Cancel
                                  </CButton>
                                </CModalFooter>
                              </CModal>
                            </CCardBody>
                          </CCollapse>
                        );
                      },
                    }}
                  />
                )}
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </div>
    );
  }
}
