import React, { useEffect, useState } from "react";
import { IMAGES_BASE_URL, API_BASE_URL } from "../../../config";
import axios from "axios";
import {
    CBadge,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CDataTable,
    CRow,
    CButton,
    CAlert,
    CFormGroup,
    CLabel,
    CSpinner,
} from "@coreui/react";
import apiUser from "../apiUser";
import { useHistory } from "react-router-dom";

export default function Banners() {
    const [modal, setModal] = useState(false);
    const [error, setError] = useState(null);
    const [errorDel, setErrorDel] = useState("");
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [imgPrincipal, setImgPrincipal] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [pImageThumb, setpImageThumb] = useState(null);
    const [uploadingImage, setUploadingImage] = useState(false);
    const [bannerAlt, setBannerAlt] = useState('');
    const [bannerPosition, setBannerPosition] = useState('');
    const [doneuploadingfile, setDoneuploadingfile] = useState(true);
    const [loading, setLoading] = useState("");
    const [success, setSuccess] = useState(false);
    const [isImage, setIsImage] = useState(false);
    const [reload, setReload] = useState(false);
    const history = useHistory();


    const handleAltChange = (e) => {
        setBannerAlt(e.target.value);
    };

    // const handlePositionChange = (e) => {
    //     setBannerPosition(e.target.value);
    // };


    const fields = [
        {
            key: "id",
            label: "id",
            // _style: { width: "20%" },
        },
        {
            key: "path",
            label: "image",
            // _style: { width: "20%" },
        },
        {
            key: "alt",
            label: "alt",
            //_style: { width: "20%" },
        },
        {
            key: "position",
            label: "position",
            //_style: { width: "20%" },
        },

    ];

    const handleFileSection = (event, pose) => {
        setBannerPosition(pose)
        setUploadingImage(true);
        let file = event.target.files[0];

        // Check if file is an image or a video
        const isImageFile = file.type.startsWith("image/");
        const isVideoFile = file.type.startsWith("video/");
        setIsImage(isImageFile); // Update state to indicate if it's an image

        // Handle file size restrictions
        const maxImageSize = 5 * 1024 * 1024; // 5 MB
        const maxVideoSize = 15 * 1024 * 1024; // 15 MB

        if ((isImageFile && file.size > maxImageSize) || (isVideoFile && file.size > maxVideoSize)) {
            alert(`File size exceeds the limit. Max size for ${isImageFile ? "images" : "videos"} is ${isImageFile ? "5 MB" : "15 MB"}.`);
            setpImageThumb(null);
            setImgPrincipal(null);
            setUploadingImage(false);
            event.target.value = ''; // Reset the input value
            return;
        }

        try {
            if (isImageFile) {
                // Compress the image
                // let options = {
                //     maxSizeMB: 1,
                //     maxWidthOrHeight: 1920,
                // };
                // file = await imageCompression(file, options);
                setpImageThumb(URL.createObjectURL(file)); // Set image thumbnail
            } else if (isVideoFile) {
                // Set file name for video
                setpImageThumb(file.name); // Use file name as the "thumbnail"
            }

            // Set the processed file (image or video)
            setImgPrincipal(file);
        } catch (error) {
            console.error("Error processing file:", error);
            setpImageThumb(null);
            setImgPrincipal(null);
        }

        setUploadingImage(false);
    };


    const handleSubmit = (pose) => {

        setLoading(true);


        if (!doneuploadingfile) {
            alert("not done uploading files yet");
            setLoading(false);

            return false;
        } else {
            setSubmitted(true);
            if (imgPrincipal) {
                setLoading(true);

                const formData = new FormData();

                formData.append("imgPrincipal", imgPrincipal);
                formData.append("position", pose);
                formData.append("alt", bannerAlt);


                //console.log(formData.get("imgPrincipal"));
                apiUser
                    .post("/banners/add",
                        formData
                    )
                    .then((res) => {
                        console.log('rrrrrssss', res);
                        if (res.data.status.toString() === "ok") {
                            setSuccess(true);
                            console.log('vvvvvvv');
                            setReload(!reload)
                            //window.location.reload(false);
                            // history.push("/5467854/banners");
                            //if (res.data.id) { history.push("/5467854/banners");}
                        }
                        setLoading(false);
                    })
                    .catch((error) => {
                        //console.log(" errors : ", error);
                        setLoading(false);
                        if (error.response && error.response.status === 401) {
                            // logoutLocal();
                            //window.location.replace("/");
                        }
                    });
            } else {
                window.scrollTo(0, 200);
                setLoading(false);
            }
        }

    };

    useEffect(() => {
        setIsLoaded(false);
        console.log('rrrreeee<<<');
        axios(`${API_BASE_URL}/banners/all`)
            .then((res) => res.data)
            .then(
                (result) => {
                    setIsLoaded(true);
                    console.log('result<<<', result);
                    setItems(result);

                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            );
    }, [reload]);

    // Find the element with position = 'home'
    const element = (pose) => items.find((item) => item.position === pose);

    // Helper function to check if the path is an image
    const isImageF = (path) => /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(path);

    // Helper function to check if the path is a video
    const isVideo = (path) => /\.(mp4|avi|mov|wmv|flv|webm|mkv)$/i.test(path);


    if (error) {
        return <div>Erreur : {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Chargement...</div>;
    } else {
        return (
            <div className="annonceTableDashboard">
                <CRow>
                    <CCol xl={12}>
                        <CCard>
                            {/* <CCardHeader>{items && items.length} Banners
                            </CCardHeader> */}
                            <CCardBody>
                                {errorDel !== "" ? (
                                    <CAlert color="danger" closeButton>
                                        {errorDel}
                                    </CAlert>
                                ) : (
                                    ""
                                )}

                                <div className="addnewbanner">
                                    <strong>Home banner</strong>
                                    {isLoaded && element("home") &&
                                        <img
                                            style={{ marginLeft: "15px", height: "85px", width: "auto" }}
                                            src={IMAGES_BASE_URL + '/public/uploads/banners/' + element("home").path}
                                            alt="preview"
                                            className="img-fluid img-thumbnail"
                                        />}
                                    <br /> <br />

                                    <CRow>
                                        <CCol>
                                            {/* Input for Principal Image/Video */}
                                            <CFormGroup style={{ display: "inline-block" }}>
                                                <CLabel htmlFor="pFile">Image/Video</CLabel> &nbsp; &nbsp;
                                                <input
                                                    type="file"
                                                    name="pFile"
                                                    onChange={(e) => handleFileSection(e, 'home')}
                                                    accept="image/*,video/*"
                                                />
                                            </CFormGroup>
                                            {uploadingImage && (
                                                <span
                                                    style={{
                                                        height: "fit-content",
                                                        marginLeft: "10px",
                                                        lineHeight: "90px",
                                                    }}
                                                >
                                                    <CSpinner color="default" size="sm" />
                                                </span>
                                            )}
                                            {!uploadingImage && pImageThumb && bannerPosition == 'home' && (
                                                isImage ? (
                                                    <img
                                                        style={{ height: "85px", width: "auto" }}
                                                        src={pImageThumb}
                                                        alt="preview"
                                                        className="img-fluid img-thumbnail"
                                                    />
                                                ) : (''
                                                )
                                            )}

                                            {submitted && !imgPrincipal && (
                                                <span className="invalid-feedback error">
                                                    Veuillez uploader une image ou une vidéo
                                                </span>
                                            )}
                                        </CCol>
                                    </CRow>
                                    <br />

                                    {/* Input for Text Alt */}
                                    <CRow>
                                        {/* <CCol md="6">
                                            <CFormGroup>
                                                <CLabel style={{ display: "inline-block", width: "20%" }} htmlFor="bannerPosition">Banner Position</CLabel>
                                                <select style={{ display: "inline-block", width: "70%" }}
                                                    id="bannerPosition"
                                                    name="bannerPosition"
                                                    className="form-control"
                                                    onChange={handlePositionChange}
                                                >
                                                    <option value="">Select Position</option>
                                                    <option value="home">Home Page</option>
                                                    <option value="vehicle">Vehicle Page</option>
                                                    <option value="realEstate">Real Estate Page</option>
                                                    <option value="goodPlan">Good Plan Page</option>
                                                    <option value="animal">Animal Page</option>
                                                    <option value="article">Article Page</option>
                                                    <option value="service">Service Page</option>
                                                </select>
                                            </CFormGroup>
                                            {submitted && !bannerPosition && (
                                                <span className="invalid-feedback error">
                                                    Veuillez
                                                </span>
                                            )}
                                        </CCol> */}
                                        <CCol md="6">
                                            <CFormGroup>
                                                <CLabel style={{ display: "inline-block", width: "20%" }} htmlFor="bannerAlt">Image alt</CLabel>
                                                <input style={{ display: "inline-block", width: "70%" }}
                                                    type="text"
                                                    id="bannerAlt"
                                                    name="bannerAlt"
                                                    className="form-control"
                                                    placeholder="Enter banner alt"
                                                    onChange={handleAltChange}
                                                />
                                            </CFormGroup>
                                        </CCol>


                                    </CRow>
                                    <br />

                                    {/* Submit Button */}
                                    <CRow>
                                        <CCol>
                                            <button
                                                type="button"
                                                disabled={loading}
                                                className="btn btn-primary"
                                                onClick={() => { handleSubmit('home') }}
                                            >
                                                Submit {loading && <CSpinner size="sm" />}
                                            </button>
                                        </CCol>
                                    </CRow>
                                </div>

                                <br /> <br />

                                <div className="addnewbanner">
                                    <strong>Vehicle banner</strong>
                                    {isLoaded && element("vehicle") &&
                                        <img
                                            style={{ marginLeft: "15px", height: "85px", width: "auto" }}
                                            src={IMAGES_BASE_URL + '/public/uploads/banners/' + element("vehicle").path}
                                            alt="preview"
                                            className="img-fluid img-thumbnail"
                                        />}
                                    <br /> <br />

                                    <CRow>
                                        <CCol>
                                            <CFormGroup style={{ display: "inline-block" }}>
                                                <CLabel htmlFor="pFile">Image/Video</CLabel> &nbsp; &nbsp;
                                                <input
                                                    type="file"
                                                    name="pFile"
                                                    onChange={(e) => handleFileSection(e, 'vehicle')}
                                                    accept="image/*,video/*"
                                                />
                                            </CFormGroup>
                                            {uploadingImage && (
                                                <span
                                                    style={{
                                                        height: "fit-content",
                                                        marginLeft: "10px",
                                                        lineHeight: "90px",
                                                    }}
                                                >
                                                    <CSpinner color="default" size="sm" />
                                                </span>
                                            )}
                                            {!uploadingImage && pImageThumb && bannerPosition == 'vehicle' && (
                                                isImage ? (
                                                    <img
                                                        style={{ height: "85px", width: "auto" }}
                                                        src={pImageThumb}
                                                        alt="preview"
                                                        className="img-fluid img-thumbnail"
                                                    />
                                                ) : (''
                                                )
                                            )}

                                            {submitted && !imgPrincipal && (
                                                <span className="invalid-feedback error">
                                                    Veuillez uploader une image ou une vidéo
                                                </span>
                                            )}
                                        </CCol>
                                    </CRow>

                                    <CRow>
                                        <CCol md="6">
                                            <CFormGroup>
                                                <CLabel style={{ display: "inline-block", width: "20%" }} htmlFor="bannerAlt">Image alt</CLabel>
                                                <input style={{ display: "inline-block", width: "70%" }}
                                                    type="text"
                                                    id="bannerAlt"
                                                    name="bannerAlt"
                                                    className="form-control"
                                                    placeholder="Enter banner alt"
                                                    onChange={handleAltChange}
                                                />
                                            </CFormGroup>
                                        </CCol>


                                    </CRow>

                                    <CRow>
                                        <CCol>
                                            <button
                                                type="button"
                                                disabled={loading}
                                                className="btn btn-primary"
                                                onClick={() => handleSubmit('vehicle')}
                                            >
                                                Submit {loading && <CSpinner size="sm" />}
                                            </button>
                                        </CCol>
                                    </CRow>
                                </div>
                                <br /> <br />

                                <div className="addnewbanner">
                                    <strong>RealEstate banner</strong>
                                    {isLoaded && element("realEstate") &&
                                        <img
                                            style={{ marginLeft: "15px", height: "85px", width: "auto" }}
                                            src={IMAGES_BASE_URL + '/public/uploads/banners/' + element("realEstate").path}
                                            alt="preview"
                                            className="img-fluid img-thumbnail"
                                        />}
                                    <br /> <br />

                                    <CRow>
                                        <CCol>
                                            <CFormGroup style={{ display: "inline-block" }}>
                                                <CLabel htmlFor="pFile">Image/Video</CLabel> &nbsp; &nbsp;
                                                <input
                                                    type="file"
                                                    name="pFile"
                                                    onChange={(e) => handleFileSection(e, 'realEstate')}
                                                    accept="image/*,video/*"
                                                />
                                            </CFormGroup>
                                            {uploadingImage && (
                                                <span
                                                    style={{
                                                        height: "fit-content",
                                                        marginLeft: "10px",
                                                        lineHeight: "90px",
                                                    }}
                                                >
                                                    <CSpinner color="default" size="sm" />
                                                </span>
                                            )}
                                            {!uploadingImage && pImageThumb && bannerPosition == 'realEstate' && (
                                                isImage ? (
                                                    <img
                                                        style={{ height: "85px", width: "auto" }}
                                                        src={pImageThumb}
                                                        alt="preview"
                                                        className="img-fluid img-thumbnail"
                                                    />
                                                ) : (''
                                                )
                                            )}

                                            {submitted && !imgPrincipal && (
                                                <span className="invalid-feedback error">
                                                    Veuillez uploader une image ou une vidéo
                                                </span>
                                            )}
                                        </CCol>
                                    </CRow>

                                    <CRow>
                                        <CCol md="6">
                                            <CFormGroup>
                                                <CLabel style={{ display: "inline-block", width: "20%" }} htmlFor="bannerAlt">Image alt</CLabel>
                                                <input style={{ display: "inline-block", width: "70%" }}
                                                    type="text"
                                                    id="bannerAlt"
                                                    name="bannerAlt"
                                                    className="form-control"
                                                    placeholder="Enter banner alt"
                                                    onChange={handleAltChange}
                                                />
                                            </CFormGroup>
                                        </CCol>


                                    </CRow>

                                    <CRow>
                                        <CCol>
                                            <button
                                                type="button"
                                                disabled={loading}
                                                className="btn btn-primary"
                                                onClick={() => handleSubmit('realEstate')}
                                            >
                                                Submit {loading && <CSpinner size="sm" />}
                                            </button>
                                        </CCol>
                                    </CRow>
                                </div>
                                <br /> <br />

                                <div className="addnewbanner">
                                    <strong>GoodPlan banner</strong>
                                    {isLoaded && element("goodPlan") &&
                                        <img
                                            style={{ marginLeft: "15px", height: "85px", width: "auto" }}
                                            src={IMAGES_BASE_URL + '/public/uploads/banners/' + element("goodPlan").path}
                                            alt="preview"
                                            className="img-fluid img-thumbnail"
                                        />}
                                    <br /> <br />

                                    <CRow>
                                        <CCol>
                                            <CFormGroup style={{ display: "inline-block" }}>
                                                <CLabel htmlFor="pFile">Image/Video</CLabel> &nbsp; &nbsp;
                                                <input
                                                    type="file"
                                                    name="pFile"
                                                    onChange={(e) => handleFileSection(e, 'goodPlan')}
                                                    accept="image/*,video/*"
                                                />
                                            </CFormGroup>
                                            {uploadingImage && (
                                                <span
                                                    style={{
                                                        height: "fit-content",
                                                        marginLeft: "10px",
                                                        lineHeight: "90px",
                                                    }}
                                                >
                                                    <CSpinner color="default" size="sm" />
                                                </span>
                                            )}
                                            {!uploadingImage && pImageThumb && bannerPosition == 'goodPlan' && (
                                                isImage ? (
                                                    <img
                                                        style={{ height: "85px", width: "auto" }}
                                                        src={pImageThumb}
                                                        alt="preview"
                                                        className="img-fluid img-thumbnail"
                                                    />
                                                ) : (''
                                                )
                                            )}

                                            {submitted && !imgPrincipal && (
                                                <span className="invalid-feedback error">
                                                    Veuillez uploader une image ou une vidéo
                                                </span>
                                            )}
                                        </CCol>
                                    </CRow>

                                    <CRow>
                                        <CCol md="6">
                                            <CFormGroup>
                                                <CLabel style={{ display: "inline-block", width: "20%" }} htmlFor="bannerAlt">Image alt</CLabel>
                                                <input style={{ display: "inline-block", width: "70%" }}
                                                    type="text"
                                                    id="bannerAlt"
                                                    name="bannerAlt"
                                                    className="form-control"
                                                    placeholder="Enter banner alt"
                                                    onChange={handleAltChange}
                                                />
                                            </CFormGroup>
                                        </CCol>


                                    </CRow>

                                    <CRow>
                                        <CCol>
                                            <button
                                                type="button"
                                                disabled={loading}
                                                className="btn btn-primary"
                                                onClick={() => handleSubmit('goodPlan')}
                                            >
                                                Submit {loading && <CSpinner size="sm" />}
                                            </button>
                                        </CCol>
                                    </CRow>
                                </div>
                                <br /> <br />

                                <div className="addnewbanner">
                                    <strong>Animal banner</strong>
                                    {isLoaded && element("animal") &&
                                        <img
                                            style={{ marginLeft: "15px", height: "85px", width: "auto" }}
                                            src={IMAGES_BASE_URL + '/public/uploads/banners/' + element("animal").path}
                                            alt="preview"
                                            className="img-fluid img-thumbnail"
                                        />}
                                    <br /> <br />

                                    <CRow>
                                        <CCol>
                                            <CFormGroup style={{ display: "inline-block" }}>
                                                <CLabel htmlFor="pFile">Image/Video</CLabel> &nbsp; &nbsp;
                                                <input
                                                    type="file"
                                                    name="pFile"
                                                    onChange={(e) => handleFileSection(e, 'animal')}
                                                    accept="image/*,video/*"
                                                />
                                            </CFormGroup>
                                            {uploadingImage && (
                                                <span
                                                    style={{
                                                        height: "fit-content",
                                                        marginLeft: "10px",
                                                        lineHeight: "90px",
                                                    }}
                                                >
                                                    <CSpinner color="default" size="sm" />
                                                </span>
                                            )}
                                            {!uploadingImage && pImageThumb && bannerPosition == 'animal' && (
                                                isImage ? (
                                                    <img
                                                        style={{ height: "85px", width: "auto" }}
                                                        src={pImageThumb}
                                                        alt="preview"
                                                        className="img-fluid img-thumbnail"
                                                    />
                                                ) : (''
                                                )
                                            )}

                                            {submitted && !imgPrincipal && (
                                                <span className="invalid-feedback error">
                                                    Veuillez uploader une image ou une vidéo
                                                </span>
                                            )}
                                        </CCol>
                                    </CRow>

                                    <CRow>
                                        <CCol md="6">
                                            <CFormGroup>
                                                <CLabel style={{ display: "inline-block", width: "20%" }} htmlFor="bannerAlt">Image alt</CLabel>
                                                <input style={{ display: "inline-block", width: "70%" }}
                                                    type="text"
                                                    id="bannerAlt"
                                                    name="bannerAlt"
                                                    className="form-control"
                                                    placeholder="Enter banner alt"
                                                    onChange={handleAltChange}
                                                />
                                            </CFormGroup>
                                        </CCol>


                                    </CRow>

                                    <CRow>
                                        <CCol>
                                            <button
                                                type="button"
                                                disabled={loading}
                                                className="btn btn-primary"
                                                onClick={() => handleSubmit('animal')}
                                            >
                                                Submit {loading && <CSpinner size="sm" />}
                                            </button>
                                        </CCol>
                                    </CRow>
                                </div>
                                <br /> <br />

                                <div className="addnewbanner">
                                    <strong>Article banner</strong>
                                    {isLoaded && element("article") &&
                                        <img
                                            style={{ marginLeft: "15px", height: "85px", width: "auto" }}
                                            src={IMAGES_BASE_URL + '/public/uploads/banners/' + element("article").path}
                                            alt="preview"
                                            className="img-fluid img-thumbnail"
                                        />}
                                    <br /> <br />

                                    <CRow>
                                        <CCol>
                                            <CFormGroup style={{ display: "inline-block" }}>
                                                <CLabel htmlFor="pFile">Image/Video</CLabel> &nbsp; &nbsp;
                                                <input
                                                    type="file"
                                                    name="pFile"
                                                    onChange={(e) => handleFileSection(e, 'article')}
                                                    accept="image/*,video/*"
                                                />
                                            </CFormGroup>
                                            {uploadingImage && (
                                                <span
                                                    style={{
                                                        height: "fit-content",
                                                        marginLeft: "10px",
                                                        lineHeight: "90px",
                                                    }}
                                                >
                                                    <CSpinner color="default" size="sm" />
                                                </span>
                                            )}
                                            {!uploadingImage && pImageThumb && bannerPosition == 'article' && (
                                                isImage ? (
                                                    <img
                                                        style={{ height: "85px", width: "auto" }}
                                                        src={pImageThumb}
                                                        alt="preview"
                                                        className="img-fluid img-thumbnail"
                                                    />
                                                ) : (''
                                                )
                                            )}

                                            {submitted && !imgPrincipal && (
                                                <span className="invalid-feedback error">
                                                    Veuillez uploader une image ou une vidéo
                                                </span>
                                            )}
                                        </CCol>
                                    </CRow>

                                    <CRow>
                                        <CCol md="6">
                                            <CFormGroup>
                                                <CLabel style={{ display: "inline-block", width: "20%" }} htmlFor="bannerAlt">Image alt</CLabel>
                                                <input style={{ display: "inline-block", width: "70%" }}
                                                    type="text"
                                                    id="bannerAlt"
                                                    name="bannerAlt"
                                                    className="form-control"
                                                    placeholder="Enter banner alt"
                                                    onChange={handleAltChange}
                                                />
                                            </CFormGroup>
                                        </CCol>


                                    </CRow>

                                    <CRow>
                                        <CCol>
                                            <button
                                                type="button"
                                                disabled={loading}
                                                className="btn btn-primary"
                                                onClick={() => handleSubmit('article')}
                                            >
                                                Submit {loading && <CSpinner size="sm" />}
                                            </button>
                                        </CCol>
                                    </CRow>
                                </div>
                                <br /> <br />

                                <div className="addnewbanner">
                                    <strong>Service banner</strong>
                                    {isLoaded && element("service") &&
                                        <img
                                            style={{ marginLeft: "15px", height: "85px", width: "auto" }}
                                            src={IMAGES_BASE_URL + '/public/uploads/banners/' + element("service").path}
                                            alt="preview"
                                            className="img-fluid img-thumbnail"
                                        />}
                                    <br /> <br />

                                    <CRow>
                                        <CCol>
                                            <CFormGroup style={{ display: "inline-block" }}>
                                                <CLabel htmlFor="pFile">Image/Video</CLabel> &nbsp; &nbsp;
                                                <input
                                                    type="file"
                                                    name="pFile"
                                                    onChange={(e) => handleFileSection(e, 'service')}
                                                    accept="image/*,video/*"
                                                />
                                            </CFormGroup>
                                            {uploadingImage && (
                                                <span
                                                    style={{
                                                        height: "fit-content",
                                                        marginLeft: "10px",
                                                        lineHeight: "90px",
                                                    }}
                                                >
                                                    <CSpinner color="default" size="sm" />
                                                </span>
                                            )}
                                            {!uploadingImage && pImageThumb && bannerPosition == 'service' && (
                                                isImage ? (
                                                    <img
                                                        style={{ height: "85px", width: "auto" }}
                                                        src={pImageThumb}
                                                        alt="preview"
                                                        className="img-fluid img-thumbnail"
                                                    />
                                                ) : (''
                                                )
                                            )}

                                            {submitted && !imgPrincipal && (
                                                <span className="invalid-feedback error">
                                                    Veuillez uploader une image ou une vidéo
                                                </span>
                                            )}
                                        </CCol>
                                    </CRow>

                                    <CRow>
                                        <CCol md="6">
                                            <CFormGroup>
                                                <CLabel style={{ display: "inline-block", width: "20%" }} htmlFor="bannerAlt">Image alt</CLabel>
                                                <input style={{ display: "inline-block", width: "70%" }}
                                                    type="text"
                                                    id="bannerAlt"
                                                    name="bannerAlt"
                                                    className="form-control"
                                                    placeholder="Enter banner alt"
                                                    onChange={handleAltChange}
                                                />
                                            </CFormGroup>
                                        </CCol>


                                    </CRow>

                                    <CRow>
                                        <CCol>
                                            <button
                                                type="button"
                                                disabled={loading}
                                                className="btn btn-primary"
                                                onClick={() => handleSubmit('service')}
                                            >
                                                Submit {loading && <CSpinner size="sm" />}
                                            </button>
                                        </CCol>
                                    </CRow>
                                </div>

                                {/* {isLoaded && (
                                    <CDataTable
                                        items={items}
                                        fields={fields}
                                        columnFilter
                                        tableFilter
                                        footer
                                        itemsPerPageSelect
                                        itemsPerPage={10}
                                        hover
                                        sorter
                                        pagination
                                        scopedSlots={{
                                            path: (item) => (
                                                <td className="titleTdNewsTableDashboard titleTdNewsTableDashboard1">
                                                    <img
                                                        width="100"
                                                        className="img-fluid img-thumbnail "
                                                        src={
                                                            item.path
                                                                ? `${IMAGES_BASE_URL}/public/uploads/banners/${item.path}`
                                                                : ""
                                                        }
                                                        alt={item.alt ? item.alt : item.path ? item.path : ''}
                                                    />
                                                </td>
                                            ),
                                            alt: (item) => (
                                                <td>
                                                    {item.alt}
                                                </td>
                                            ),
                                            position: (item) => (
                                                <td>
                                                    {item.position}
                                                </td>
                                            ),
                                        }}
                                    />
                                )} */}
                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>
            </div>
        );
    }
}
