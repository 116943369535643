import React, { Component } from "react";
import { API_BASE_URL } from "../../../config";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function ConfirmAccount(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const uid = searchParams.get("uid");
  const ukey = searchParams.get("ukey");

  useEffect(() => {
    //console.log("this.props");
    //console.log(uid, "---", ukey);
    const formData = new FormData();
    formData.append("uid", uid);
    formData.append("ukey", ukey);

    axios
      .post(API_BASE_URL + "/members/confirm-account", formData)
      .then((res) => {
        //console.log(res.data);
        setIsLoading(false);
        if (res.data.status == "1") {
          setIsConfirmed(true);
          setTimeout(() => {
            window.location.href = "/members/login";
          }, 2000);
        } else setIsError(true);
      })

      .catch((error) => {
        //console.log("errors : ", error);
        setIsLoading(false);
        setIsError(true);
      });

    // axios(`${API_BASE_URL}/members/confirm-account`, formData)
    //   .then((res) => res.data)
    //   .then(
    //     (result) => {
    //       setIsConfirmed(true);
    //       console.log(result);
    //     },
    //     (error) => {
    //       setIsError(true);
    //     }
    //   );
  }, []);

  if (isLoading) {
    return (
      <div className="container">
        <main className="l-main lmain2">
          <div className="l-section l-section--archive">
            <div className="loading_logo">
              <img
                src={"assets/icons/tunisia-living-loading-icon.gif"}
                alt="tunisia living loading icon"
              />
              <p>Chargement...</p>
            </div>
          </div>
        </main>
      </div>
    );
  } else if (isConfirmed)
    return (
      <div className="container">
        <main className="l-main lmain2">
          <div className="l-section l-section--archive">
            <div className="loading_logo">
              <br />
              <img
                src={"assets/icons/success.png"}
                alt="tunisia living loading icon"
              />
              <p style={{ color: "#1c9070" }}>
                Votre compte a été confirmé avec succès. Vous pouvez maintenant
                vous connecter et profiter de nos services.
              </p>
              {/* Your annonce was submitted successfully , it will be published once it is reviewed and approved from our side , for now you can still review and edit it in your profile page. */}

            </div>
          </div>
        </main>
      </div>
    );
  else
    return (
      <div className="container">
        <main className="l-main lmain2">
          <div className="l-section l-section--archive">
            <div className="loading_logo">
              <br />
              <img
                src={"assets/icons/fail.png"}
                alt="tunisia living loading icon"
              />
              <p style={{ color: "#db382d" }}>
                Votre compte n'a pas été confirmé. Veuillez réessayer ou
                contacter le support sur
                <a
                  style={{ color: "#db382d" }}
                  href="mailto:support@tunisialiving.tn "
                >
                  {" "}
                  support@tunisialiving.tn
                </a>
              </p>
            </div>
          </div>
        </main>
      </div>
    );
}
export default ConfirmAccount;
