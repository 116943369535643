import React, { useEffect, useState } from "react";
import { API_BASE_URL, IMAGES_BASE_URL, tokenU } from "../../config";
import Moment from "react-moment";
import {
  CBadge,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CButton,
  CAlert,
  CCollapse, CSpinner
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { Link } from "react-router-dom";
import axios from "axios";
import { getBadge, textBadge } from "src/actions";
import apiUser from "./apiUser";

export default function Consults() {
  const [modal, setModal] = useState(false);
  const [error, setError] = useState(null);
  const [errorDel, setErrorDel] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };
  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      // newDetails = [...details, index];
      newDetails = [details, index];
    }
    setDetails(newDetails);
  };

  const changeStatus = (id, st) => {
    console.log('vvvv', id, st)
    const formData = new FormData();
    formData.append("itemId", id);

    formData.append("status", st);
    setLoading(true)

    apiUser
      .post(`/consults/changeStatus`, formData)
      .then((res) => {
        console.log("dddddddd");
        console.log(res);
        if (res.data.status.toString() === "ok") {

          setLoading(false)
          setReload(!reload)
        }
      })
      .catch((err) => { //console.log(err);
        setLoading(false);
      }
      );

  }
  const fields = [

    {
      key: "id",
      label: "id",
      //_style: { width: "10%" },
    },
    {
      key: "name",
      label: "name",
      //_style: { width: "10%" },
    },
    {
      key: "city",
      label: "city",
      //_style: { width: "20%" },
    },
    {
      key: "brand",
      label: "brand",
      //_style: { width: "20%" },
    },
    {
      key: "phoneNumber",
      label: "phone",
      //_style: { width: "20%" },
    },
    {
      key: "note",
      label: "notes",
      //_style: { width: "20%" },
    },
    {
      key: "createdAt",
      // _style: { width: "20%" }, 
      label: "Date d'ajout"
    },
    {
      key: "status",
      // _style: { width: "20%" }, 
      label: "Statut"
    },

    {
      key: "show_details",
      label: "Actions",
      //_style: { width: "1%" },
      sorter: false,
      filter: false,
    },
  ];
  // Remarque : le tableau vide de dépendances [] indique
  // que useEffect ne s’exécutera qu’une fois, un peu comme
  // componentDidMount()

  useEffect(() => {
    apiUser
      .get("/consults/all")
      .then((res) => res.data)
      .then(
        (result) => {
          setIsLoaded(true);
          console.log('result<<<', result);
          setItems(result);
          let tempresult = result;
          // console.log(tempresult);
          setLoaded(true);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, [reload]);

  if (error) {
    return <div>Erreur : {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Chargement...</div>;
  } else {
    return (
      <div className="annonceTableDashboard">
        <CRow>
          <CCol xl={12}>
            <CCard>
              <CCardHeader>{items && items.length} Demandes

              </CCardHeader>
              <CCardBody>
                {errorDel !== "" ? (
                  <CAlert color="danger" closeButton>
                    {errorDel}
                  </CAlert>
                ) : (
                  ""
                )}
                {loaded && (
                  <CDataTable
                    items={items}
                    fields={fields}
                    columnFilter
                    tableFilter
                    footer
                    itemsPerPageSelect
                    itemsPerPage={10}
                    hover
                    sorter
                    pagination
                    scopedSlots={{
                      city: (item) => (
                        <td className="titleTdNewsTableDashboard">
                          {item.city.label}
                        </td>
                      ),
                      brand: (item) => (
                        <td className="titleTdNewsTableDashboard">
                          {item.brand.label}
                        </td>
                      ),
                      note: (item) => (
                        <td className="titleTdNewsTableDashboard">
                          {item.note ? item.note : '-'}
                        </td>
                      ),
                      status: (item) => (
                        <td>
                          <CBadge color={getBadge(item.status, item.edits)}>
                            {textBadge(item.status, item.edits)}

                          </CBadge>
                        </td>
                      ),
                      createdAt: (item) => (
                        <td>
                          <Moment format="DD/MM/YYYY">
                            {item.createdAt}
                          </Moment>
                        </td>
                      ),
                      show_details: (item, index) => {
                        return (
                          <td className="py-2">
                            <CButton
                              color="primary"
                              variant="outline"
                              shape="square"
                              size="sm"
                              onClick={() => {
                                toggleDetails(index);
                              }}
                            >
                              <CIcon name="cilSettings" />
                            </CButton>
                          </td>
                        );
                      },
                      details: (item, index) => {
                        return (
                          <CCollapse show={details.includes(index)}>
                            <CCardBody>
                              <div className="actionButtons">
                                {item.status == 'approved' ? '' :
                                  <CButton
                                    size="sm"
                                    className="ml-1 btn-primary"
                                    disabled={loading}
                                    //style={{ backgroundColor: '#2eb85c' }}
                                    onClick={() => {
                                      changeStatus(item.id, 'approved');
                                    }}
                                  >
                                    {loading && <CSpinner size="sm" />} Set Approved
                                  </CButton>}


                                {item.status == 'pending' ? '' : <CButton
                                  size="sm"
                                  className="ml-1 btn-primary"
                                  disabled={loading}
                                  onClick={() => {
                                    changeStatus(item.id, 'pending');
                                  }}
                                >
                                  {loading && <CSpinner size="sm" />} Set Pending
                                </CButton>}

                                {item.status == 'rejected' ? '' : <CButton
                                  size="sm"
                                  color="danger"
                                  className="ml-1"
                                  disabled={loading}
                                  onClick={() => changeStatus(item.id, 'rejected')}
                                >
                                  {loading && <CSpinner size="sm" />}  Set Rejected
                                </CButton>}
                              </div>
                            </CCardBody>
                          </CCollapse>
                        );
                      },
                    }}
                  />
                )}
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </div>
    );
  }
}
