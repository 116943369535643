import React, { useState, useEffect, useRef } from "react";
import { API_BASE_URL, keyHeader } from "../../config";
//import Ticker from "react-ticker";
//import { IMAGES_BASE_URL } from "../../config";
import axios from "axios";
import { Link } from "react-router-dom";
//import NewsItem from "./news/NewsItem";
//import Weather from "./widgets/weather";
//import SimilarCar from "./vehicles/cars/SimilarCar";
import ItemCard from "./itemCard";
import { CCol } from "@coreui/react";
import HtmlParser from "react-html-parser";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import NewsTicker from "./NewsTicker";
import { Helmet } from "react-helmet-async";

export const HomePage = () => {
  const IMAGES_BASE_URL = "https://www.tunisialiving.tn";
  const [infobar, setInfobar] = useState(null);
  const [infobarArr, setInfobarArr] = useState([]);
  const [infobarArrA, setInfobarArrA] = useState([]);
  const [newsAlaUne, setNewsAlaNne] = useState(null);
  const [recentNews, setRecentNews] = useState([]);
  const [allNews, setAllNews] = useState([]);
  const [newsDeux, setNewsDeux] = useState(null);
  const [vehicles, setVehicles] = useState([]);
  const [animals, setAnimals] = useState([]);
  const [realEstate, setRealEstate] = useState([]);
  const [goodPlans, setGoodPlans] = useState([]);
  const [articles, setArticles] = useState([]);
  const [services, setServices] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [banner, setBanner] = useState([]);
  const [direction, setDirection] = useState('toLeft');
  const videoRef = useRef(null);

  const settings = {
    dots: true, // Enable pagination dots
    infinite: true, // Enable infinite looping
    slidesToShow: 3, // Number of products visible at once
    slidesToScroll: 1, // Number of products to slide on arrow click
    autoplay: true, // Enable autoplay (optional)
    autoplaySpeed: 2000, // Autoplay interval in milliseconds
    arrows: true, // Enable navigation arrows
    responsive: [
      {
        breakpoint: 768, // Adjust breakpoint as needed
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576, // Adjust breakpoint as needed
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  const arabic = /[\u0600-\u06FF]/;

  const convertDate = (isoDate) => {
    let date = new Date(isoDate);

    return date.toLocaleDateString("en-GB");
  };

  //const [currentText, setCurrentText] = useState(0);
  //const [textIndex, setTextIndex] = useState(0);
  const [newsText, setNewsText] = useState(['', '', '']);
  //const texts = ['Text1', 'Text2']; // Replace with your actual text content

  useEffect(() => {
    const video = videoRef.current;

    const handleVideoEnd = () => {
      video.currentTime = 0;
      video.play();
    };

    if (video) video.addEventListener('ended', handleVideoEnd);

    // Cleanup event listener on component unmount
    return () => {
      if (video) video.removeEventListener('ended', handleVideoEnd);
    };
  }, []);


  useEffect(() => {
    let isMounted = true;
    //Create a request cancel token source
    const source = axios.CancelToken.source();

    axios(`${API_BASE_URL}/banners/all`)
      .then((res) => res.data)
      .then(
        (result) => {
          console.log('result<<<', result);
          setBanner(result.find((item) => item.position === "home"));

        },
        (error) => {
        }
      );

    axios(`${API_BASE_URL}/homeNews`, {
      cancelToken: source.token,
      ...keyHeader, // Spread the keyHeader object to include its properties
    })
      .then((res) => res.data)
      .then((result) => {

        //console.log("/info", result);
        let infoa = result.info.arabic.replace(
          /@@/g,
          " <img src='assets/images/logos/retina white.svg' class='logoInInfobar' > "
        );
        let infoe = result.info.english.replace(
          /@@/g,
          " <img src='assets/images/logos/retina white.svg' class='logoInInfobar' > "
        );
        //let infof = result.info.french.replace( /@@/g, " <img src='assets/images/logos/retina white.svg' class='logoInInfobar' > " );
        let temptext = ['', '', ''];
        // temptext[0]=infof;
        temptext[1] = infoe;
        temptext[2] = infoa;
        setNewsText(temptext);
        //let infoArr= result.info.english.split('@@');
        //infoArr.push(...result.info.english.split('@@'));
        if (result.info.usearabic) {
          //console.log('in use arabic') ; console.log(result.info.arabic.split('@@')) ;
          setInfobarArr(result.info.arabic.split('@@')); setDirection('toRight')
        }
        else setInfobarArr(result.info.english.split('@@'));

        //setInfobarArrA(result.info.arabic.split('@@'))
        // setInfobar("Apple Watch abandonne les fonctionnalités d'oxygène dans le sang pour éviter l'interdiction d'importation Apple Watch abandonne les fonctionnalités d'oxygène dans le sang pour éviter l'interdiction d'importation ");
        setInfobar('infoe')
        //console.log("homeNews : ", result);
        setNewsAlaNne(result.news1);
        setRecentNews(result.news2);
        setNewsDeux(result.news3);
        let temp = [];
        temp.push(result.news1);
        temp.push(result.news3);
        temp.push(...result.news2);
        //console.log('tzmppp ; ', temp)
        setAllNews(temp);
      })
      .catch((error) => {
        // Handle the error, including cancellation
        if (axios.isCancel(error)) {
          //console.log("Request cancelled first news a la une:", error.message);
        } else {
          //console.log("Error:", error.message);
        }
      });

    // Clean up function to cancel the request on component unmount
    return () => {
      source.cancel("Request cancelled on component unmount.");
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    const source = axios.CancelToken.source();

    axios(`${API_BASE_URL}/latestAll`, {
      cancelToken: source.token,
      ...keyHeader, // Spread the keyHeader object to include its properties
    })
      .then((res) => res.data)
      .then((result) => {
        //console.log("latestAll", result);
        setVehicles(result.vehicles);
        setRealEstate(result.realEstate);
        setGoodPlans(result.goodPlans);
        setAnimals(result.animals);
        setArticles(result.articles);
        setServices(result.services);
        setJobs(result.jobs);
      })
      .catch((error) => {
        // Handle the error, including cancellation
        if (axios.isCancel(error)) {
          //console.log("Request cancelled latestAll:", error.message);
        } else {
          //console.log("Error:", error.message);
        }
      });

    // Clean-up:
    // Clean up function to cancel the request on component unmount
    return () => {
      source.cancel("Request cancelled on component unmount.");
    };
  }, []);

  const isVideo = (path) => /\.(mp4|avi|mov|wmv|flv|webm|mkv)$/i.test(path);

  return (
    <>
      <Helmet>
        <title>Tunisia Living</title>
        <meta property="og:title" content="Tunisia Living - Social, Culturel et économique" />
        <meta property="og:description" content="Tunisia Living - Social, Culturel et économique" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="article" />
        <meta name="description" content="Tunisia Living - Social, Culturel et économique" />
        <meta name="keywords" content="Tunisia,Living,social,culturel,economique" />
      </Helmet>

      <div className="mainBanner" style={{ width: '100%', height: '380px', background: 'black', display: 'contents' }}>
        {/* <img
          src={"assets/images/banner-animer-TL-SD-480p.gif"}
          alt="tunisia living home banner"
      
        /> */}
        {banner && !isVideo(banner.path) &&
          <img
            src={IMAGES_BASE_URL + '/public/uploads/banners/' + banner.path}
            alt={banner.alt}
          />

        }
        {banner && isVideo(banner.path) && <video
          style={{ width: '100%', height: '100%' }}
          ref={videoRef}
          src={IMAGES_BASE_URL + '/public/uploads/banners/' + banner.path}//"assets/images/TL-home-video.mp4"
          autoPlay
          controls
          muted
        >
          Your browser does not support the video tag.
        </video>}
      </div>

      <div className={`ticker-wrapper ticker-container2 ${arabic.test(infobarArr[0]) ? 'arabicFontClass' : ''}`}>

        {infobar &&
          <NewsTicker newsItems={infobarArr} direction={direction} speed={5} />
        }

      </div>

      <div className="container">
        <main className="l-main homeMain">
          <section className="l-section desktopNewsSection ">
            <Link
              to="/"
              data-region-change="false"
              className="c-link c-link--large"
              style={{ marginBottom: '15px' }}
            >
              <div className="ob-widget-header">
                <span className="c-link__label c-link__label--lowercase c-link c-link--large">
                  à la une
                </span>
                <span className="c-link__icon"></span>
              </div>
            </Link>
            <div className="homeNewsSectionTwoMain">
              <ul className="homeNewsSectionTwoMainUl homeNewsSection c-posts c-posts--tile c-posts--grid c-posts--gridMosaic c-posts--highlightFirst l-section__posts">
                <li
                  className={`c-posts__item  c-posts__item--embedVideo ${newsAlaUne && arabic.test(newsAlaUne.title) ? 'arabicFontClass' : ''} `}
                  data-container-id="stories-1-0"
                >
                  <Link
                    to={
                      newsAlaUne ?
                        `/news/${newsAlaUne.id}-${newsAlaUne.category.id}/${newsAlaUne.category.slug}/${newsAlaUne.slug}`
                        : ''}
                    className="c-posts__inner"
                  >
                    <div>
                      <div className="c-posts__headlineLink">
                        <div className="c-posts__headline ">
                          <span className="c-posts__headlineText">
                            {newsAlaUne && newsAlaUne.title}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="c-posts__media  c-posts__media--video c-videoPlay">
                      {newsAlaUne && newsAlaUne.path ? (
                        <img
                          src={`${IMAGES_BASE_URL}/public/uploads/actualites/${newsAlaUne.path}`}
                          alt={newsAlaUne.title}
                          className="c-posts__thumbnail"
                        />
                      ) : (
                        <span className="c-posts__thumbnail" />
                      )}

                      <div className="c-posts__overlay">
                        <div className="c-posts__icon c-videoPlay__icon  c-posts__icon--embedVideo postCategory">
                          {newsAlaUne && newsAlaUne.category
                            ? newsAlaUne.category.label
                            : "  "}
                        </div>
                      </div>
                    </div>
                    <div className="c-posts__details">
                      <div className="c-posts__detailsLink">
                        <div className="c-posts__about c-posts__about--multiple">
                          <div className="c-posts__info c-posts__info--highlight">
                            {newsAlaUne && newsAlaUne.category
                              ? newsAlaUne.category.label
                              : "  "}
                          </div>
                          <div className="c-posts__info postCreatedAT">
                            {newsAlaUne && newsAlaUne.createdAt && convertDate(newsAlaUne.createdAt)}
                          </div>
                        </div>
                        <div className="c-posts__excerpt">
                          {newsAlaUne && newsAlaUne.content && <div className="u-lineClamp u-lineClampSub">
                            {newsAlaUne.sections && newsAlaUne.sections.length > 0 && newsAlaUne.sections[0].position && newsAlaUne.sections[0].position < 0
                              ? HtmlParser(newsAlaUne.content)
                              : HtmlParser(decodeURIComponent(newsAlaUne.content))}

                          </div>}
                        </div>
                      </div>
                    </div>
                  </Link>
                </li>
                <li
                  className={`c-posts__item  c-posts__item--embedVideo ${newsDeux && arabic.test(newsDeux.title) ? 'arabicFontClass' : ''} `}
                  data-container-id="stories-1-0"
                >
                  <Link
                    to={
                      newsDeux ?
                        `/news/${newsDeux.id}-${newsDeux.category.id}/${newsDeux.category.slug}/${newsDeux.slug}`
                        : ''}
                    className="c-posts__inner"
                  >
                    <div>
                      <div className="c-posts__headlineLink">
                        <div className="c-posts__headline ">
                          <span className="c-posts__headlineText">
                            {newsDeux && newsDeux.title}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="c-posts__media  c-posts__media--video c-videoPlay">
                      {newsDeux && newsDeux.path ? (
                        <img
                          src={`${IMAGES_BASE_URL}/public/uploads/actualites/${newsDeux.path}`}
                          alt={newsDeux.title}
                          className="c-posts__thumbnail"
                        />
                      ) : (
                        <span className="c-posts__thumbnail" />
                      )}

                      <div className="c-posts__overlay">
                        <div className="c-posts__icon c-videoPlay__icon  c-posts__icon--embedVideo postCategory">
                          {newsDeux && newsDeux.category
                            ? newsDeux.category.label
                            : "  "}
                        </div>
                      </div>
                    </div>
                    <div className="c-posts__details">
                      <div className="c-posts__detailsLink">
                        <div className="c-posts__about c-posts__about--multiple">
                          <div className="c-posts__info c-posts__info--highlight">
                            {newsDeux && newsDeux.category
                              ? newsDeux.category.label
                              : "  "}
                          </div>
                          <div className="c-posts__info postCreatedAT">
                            {newsDeux && newsDeux.createdAt && convertDate(newsDeux.createdAt)}
                          </div>
                        </div>
                        <div className="c-posts__excerpt">
                          {newsDeux && newsDeux.content && <div className="u-lineClamp u-lineClampSub">
                            {newsDeux.sections && newsDeux.sections.length > 0 && newsDeux.sections[0].position && newsDeux.sections[0].position < 0
                              ? HtmlParser(newsDeux.content)
                              : HtmlParser(decodeURIComponent(newsDeux.content))}
                          </div>}
                        </div>
                      </div>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>


            <div className="homeNewsSectionFourSub">
              <Link
                to="/"
                data-region-change="false"
                className="c-link c-link--large"
              >
                <div className="ob-widget-header">
                  <span className="c-link__label c-link__label--lowercase c-link c-link--large">
                    Les plus récents
                  </span>
                  <span className="c-link__icon"></span>
                </div>
              </Link>
              <ul className="homeNewsSectionFourSubUl homeNewsSection c-posts c-posts--tile c-posts--grid c-posts--gridMosaic c-posts--highlightFirst l-section__posts">
                {recentNews.map((news) => {
                  return (
                    <li
                      className={`c-posts__item  c-posts__item--embedVideo ${arabic.test(news.title) ? 'arabicFontClass' : ''} `}
                      data-container-id="stories-1-0"
                      key={news.id}>
                      <Link
                        to={
                          news &&
                          `/news/${news.id}-${news.category.id}/${news.category.slug}/${news.slug}`
                        }
                        className="c-posts__inner"
                      >
                        <div className="c-posts__media  c-posts__media--video c-videoPlay">
                          {news.path ? (
                            <img
                              src={`${IMAGES_BASE_URL}/public/uploads/actualites/${news.path}`}
                              alt={news.title}
                              className="c-posts__thumbnail"
                            />
                          ) : (
                            <span className="c-posts__thumbnail" />
                          )}

                          <div className="c-posts__overlay">
                            <div className="c-posts__icon c-videoPlay__icon  c-posts__icon--embedVideo postCategory">
                              {news.category
                                ? news.category.label
                                : "  "}
                            </div>
                          </div>
                        </div>
                        <div className="c-posts__details">
                          <div className="c-posts__detailsLink">
                            <div className="c-posts__about c-posts__about--multiple">
                              <div className="c-posts__info c-posts__info--highlight">
                                {news.category
                                  ? news.category.label
                                  : "  "}
                              </div>
                              <div className="c-posts__info">
                                {news.createdAt && convertDate(news.createdAt)}
                              </div>
                            </div>
                            <div className="c-posts__excerpt">
                              <div className="u-lineClamp u-lineClampMain">
                                {news.title}
                              </div>
                              {news && news.content && <div className="u-lineClamp u-lineClampSub">

                                {news.sections && news.sections.length > 0 && news.sections[0].position && news.sections[0].position < 0
                                  ? HtmlParser(news.content)
                                  : HtmlParser(decodeURIComponent(news.content))}
                              </div>}
                            </div>
                          </div>
                        </div>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>



          </section>

          <section className="l-section mobNewsSection ">
            <Slider {...settings}>

              {allNews.map((news, index) => (
                <div
                  className={`carouselItem ${arabic.test(news.title) && 'arabicFontClass'}`}

                  key={news.id}>
                  <Link
                    to={
                      news &&
                      `/news/${news.id}-${news.category.id}/${news.category.slug}/${news.slug}`
                    }
                    className="c-posts__inner"
                  >
                    <div className="c-posts__media  c-posts__media--video c-videoPlay">
                      {news.path ? (
                        <img
                          src={`${IMAGES_BASE_URL}/public/uploads/actualites/${news.path}`}
                          alt={news.title}
                          className="c-posts__thumbnail"
                        />
                      ) : (
                        <span className="c-posts__thumbnail" />
                      )}

                      <div className="c-posts__overlay">
                        <div className="c-posts__icon c-videoPlay__icon  c-posts__icon--embedVideo">
                          {news.category
                            ? news.category.label
                            : "  "} |  {news.title}
                        </div>
                      </div>
                    </div>

                  </Link>
                </div>
              ))}
            </Slider><br />
          </section>


          <section className="annonceSection l-section l-section--compact">
            <h3 className="c-heading c-heading--component c-headingLink__heading">
              ANNONCES
            </h3>
            <div className="row itemsRow">
              {vehicles &&
                vehicles.map((element) => {
                  return (
                    <CCol md="3" xs="6" key={element.id}>
                      <ItemCard
                        item={element}
                        id={element.id}
                        table='Vehicle'
                        title={element.brand && element.model && element.model.label ?
                          element.brand.label + ' ' + element.model.label
                          : element.brand && element.model ?
                            element.brand.label + ' ' + element.model
                            : element.brand && element.category ?
                              element.brand.label + ' ' + element.category.label
                              : ''
                        }
                        price={element.price}
                        city={element.city && element.city.label}
                        image={
                          element.mainPicture
                            ? `${IMAGES_BASE_URL}/public/uploads/images/${element.type}/${element.mainPicture.name}`
                            : `assets/icons/${element.type}.svg`
                        }
                        url={`/vehicles/${element.type}/details/${element.id}`}
                        at={element.createdAt}
                      />
                    </CCol>
                  );
                })}
              {!vehicles.length && (
                <div className="loadingGif">
                  <img src="/assets/icons/loading-bar.gif" />
                </div>
              )}
            </div>

            <div className="row itemsRow">
              {realEstate &&
                realEstate.map((element) => {
                  return (
                    <CCol md="3" xs="6" key={element.id}>
                      <ItemCard
                        item={element}
                        id={element.id}
                        table='RealEstate'
                        title={element.title}
                        price={element.price}
                        city={element.city && element.city.label}
                        image={
                          element.mainPicture
                            ? `${IMAGES_BASE_URL}/public/uploads/images/${element.type}/${element.mainPicture.name}`
                            : `assets/icons/${element.type}.svg`
                        }
                        url={`/real-estates/details/${element.id}`}
                        at={element.createdAt}
                      />
                    </CCol>
                  );
                })}
              {!realEstate.length && (
                <div className="loadingGif">
                  <img src="/assets/icons/loading-bar.gif" />
                </div>
              )}
            </div>

            <div className="row itemsRow">
              {goodPlans &&
                goodPlans.map((element) => {
                  return (
                    <CCol md="3" xs="6" key={element.id}>
                      {
                        <ItemCard
                          item={element}
                          id={element.id}
                          table='GoodPlans'
                          title={
                            element.nom && element.nom.length > 0
                              ? element.nom
                              : element.typeGoodPlans.label
                          }
                          price={element.tickets
                            ? element.tickets
                            : element.price
                          }
                          subtitle={element.categoryGoodPlans.label}
                          city={element.city && element.city.label}
                          image={
                            element.mainPicture
                              ? `${IMAGES_BASE_URL}/public/uploads/images/goodPlans/${element.mainPicture.name}`
                              : "assets/icons/loisir.svg"
                          }
                          url={`/bon-plans/details/${element.id}`}
                          at={element.createdAt}
                        />
                      }
                    </CCol>
                  );
                })}
              {!goodPlans.length && (
                <div className="loadingGif">
                  <img src="/assets/icons/loading-bar.gif" />
                </div>
              )}
            </div>

            <div className="row itemsRow">
              {animals &&
                animals.map((element) => {
                  return (
                    <CCol md="3" xs="6" key={element.id}>
                      {
                        <ItemCard
                          item={element}
                          id={element.id}
                          table='Animal'
                          title={element.title}
                          subtitle={element.typeAnimal && element.typeAnimal.label}
                          city={element.city && element.city.label}
                          price={element.price ? element.price : ""}
                          image={
                            element.mainPicture
                              ? `${IMAGES_BASE_URL}/public/uploads/images/animal/${element.mainPicture.name}`
                              : "assets/icons/chien-blanc.svg"
                          }
                          url={`/animals/details/${element.id}`}
                          at={element.createdAt}
                        />
                      }
                    </CCol>
                  );
                })}
              {!animals.length && (
                <div className="loadingGif">
                  <img src="/assets/icons/loading-bar.gif" />
                </div>
              )}
            </div>

            <div className="row itemsRow">
              {articles &&
                articles.map((element) => {
                  return (
                    <CCol md="3" xs="6" key={element.id}>
                      {
                        <ItemCard
                          item={element}
                          id={element.id}
                          table='Articles'
                          title={
                            element.nom && element.nom.length > 0 ? element.nom
                              : element.categoryArticle ? element.categoryArticle.label
                                : ""
                          }
                          price={element.price}
                          subtitle={
                            element.typeArticle
                              ? element.typeArticle.label
                              : ""
                          }
                          city={element.city && element.city.label}
                          image={
                            element.mainPicture && element.typeArticle
                              ? `${IMAGES_BASE_URL}/public/uploads/images/${element.typeArticle.section}/${element.mainPicture.name}`
                              : "assets/icons/icone-electromenager.svg"
                          }
                          url={`/articles/details/${element.id}`}
                          at={element.createdAt}
                        />
                      }
                    </CCol>
                  );
                })}
              {!articles.length && (
                <div className="loadingGif">
                  <img src="/assets/icons/loading-bar.gif" />
                </div>
              )}
            </div>

            <div className="row itemsRow">
              {services &&
                services.map((element) => {
                  return (
                    <CCol md="3" xs="6" key={element.id}>
                      {
                        <ItemCard
                          item={element}
                          id={element.id}
                          table='Service'
                          title={element.title}
                          price={element.price}
                          subtitle={element.typeService.label}
                          city={element.city && element.city.label}
                          image={
                            element.mainPicture
                              ? `${IMAGES_BASE_URL}/public/uploads/images/service/${element.mainPicture.name}`
                              : "assets/icons/house.svg"
                          }
                          url={`/services/details/${element.id}`}
                          at={element.createdAt}
                        />
                      }
                    </CCol>
                  );
                })}
              {!services.length && (
                <div className="loadingGif">
                  <img src="/assets/icons/loading-bar.gif" />
                </div>
              )}
            </div>


          </section>
        </main></div>
      <div >
        <img
          src={"assets/images/banner job TL .jpg"}
          alt="tunisia living job banner"
        />
      </div>
      <div className="container">
        <main className="l-main jobsSection">
          <section className="annonceSection l-section l-section--compact">

            <h3 className="c-heading c-heading--component c-headingLink__heading">
              Offres d'emploi
            </h3>
            <div className="row itemsRow itemsRowJobOffer">
              {jobs.map((jobOffer, index) => {
                return (
                  <CCol xs="6" md="3" key={index}>

                    <ItemCard
                      item={jobOffer}
                      id={jobOffer.id}
                      table='JobOffer'
                      title={jobOffer.title}
                      subtitle={
                        jobOffer.createdBy.accountType.id == 2 ? jobOffer.createdBy.nom :
                          jobOffer.company ? jobOffer.company.name :
                            jobOffer.createdBy.company ?
                              jobOffer.createdBy.company.name
                              : 'job company'
                      }
                      image={
                        jobOffer.createdBy.accountType.id == 2 ? `${IMAGES_BASE_URL}/public/uploads/members/${jobOffer.createdBy.path}`
                          : jobOffer.company ? `${IMAGES_BASE_URL}/public/uploads/companies/${jobOffer.company.path}`
                            : jobOffer.createdBy.company.path
                              ? `${IMAGES_BASE_URL}/public/uploads/companies/${jobOffer.createdBy.company.path}`
                              : "https://www.keejob.com/static/recruiter/images/default-logo.jpg"
                      }
                      alt={
                        jobOffer.createdBy.accountType.id == 2 ? jobOffer.createdBy.nom :
                          jobOffer.company ? jobOffer.company.name :
                            jobOffer.createdBy.company ?
                              jobOffer.createdBy.company.name
                              : 'job company'
                      }
                      url={`/jobs/job-details/${jobOffer.id}/${jobOffer.sector.slug}/${jobOffer.slug}`}

                    />

                    {/* 
                    <div className="artdeco-carousel__item-container">
                    <img
                        width="100"
                        src={
                                  jobOffer.company? `${IMAGES_BASE_URL}/public/uploads/companies/${jobOffer.company.path}`
                                    : jobOffer.createdBy.company.path
                                    ? `${IMAGES_BASE_URL}/public/uploads/companies/${jobOffer.createdBy.company.path}`
                                    : "https://www.keejob.com/static/recruiter/images/default-logo.jpg"
                                }
                        loading="lazy"
                        height="100"
                        alt={
                          jobOffer.company?jobOffer.company.name:
                          jobOffer.createdBy.company ?
                          jobOffer.createdBy.company.name
                          :'job company'
                        }
                      />
                      <div className="flex-1 t-black">
                        <Link
                          to={`/jobs/job-details/${jobOffer.id}/${jobOffer.sector.slug}/${jobOffer.slug}`}
                        >
                          {jobOffer.title}
                        </Link>
                        <div>
                          {jobOffer.createdBy.company &&
                            jobOffer.createdBy.company.name}
                        </div>
                      </div>
                    </div> */}
                  </CCol>
                );
              })}
              {!jobs.length && (
                <div className="loadingGif">
                  <img src="/assets/icons/loading-bar.gif" />
                </div>
              )}
            </div>
          </section>
        </main>
      </div>
    </>
  );
};
